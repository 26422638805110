<template>
  <div class="reimburseView">
    <detail-top 
      :tabsList="tabsList" 
      :createTime="resendDetail.createTime"
      headerTitle="查看失败重发详情"
      :title="`${detailInfo.createUser || ''}提交的失败重发审批申请`"
    >
      <div class="cut" @click="handleCutPage">
        <van-icon name="exchange" />
        <span>查看请款详情</span>
      </div>
    </detail-top>
    <div :class="[resendAuditAuthority == 1 ? 'on-approval' : '', 'content-wrap']">
      <my-card v-for="(item,index) in detailInfo" :key="item.resendId" name="1" :title="`失败重发${index+1}`">
        <div class="basics-wrap">
          <my-cell title="申请时间" :value="item.createTime" />
          <my-cell title="发起部门" :value="item.departmentName" />
          <my-cell title="款项所属月份" :value="item.billMonth" />
          <my-cell title="请款事项" :value="item.reasons" />
          <my-cell title="所属合同" :value="item.contractName" />
          <my-cell title="重发金额" :value="`￥${item.resendAmount}`" />
          <my-cell title="协议附件" />
          <my-files-view :fileList="item.files" />
          <my-cell title="备注" :value="item.remark" />
          <img v-if="handleStatus(item.status)" class="status-img" :src="handleStatus(item.status)" />
        </div>
      </my-card>
      <!-- 业用请款：详情module-1，失败重发-8 -->
      <!-- 费用请款：详情module-7，失败重发-9 -->
      <approval-process name="2" v-if="resendDetail.resendId" 
        :billId="resendDetail.resendId" :module="requestType == 1 ? 8 : 9" />
    </div>
    <detail-approval-btn v-if="resendAuditAuthority == 1" :billNo="resendDetail.billNo" />
  </div>
</template>

<script>
import Vue from 'vue';
import { NavBar, Icon  } from 'vant';
import { cashdetail  } from '@/api/reimburse'
import passImg from '@/assets/approvalPassImg.png'
import rejectImg from '@/assets/approvalRejectImg.png'

Vue.use(NavBar).use(Icon);

export default {
  name: "reimburseView",
  data() {
    return {
      status: '',
      detailId: '', // id
      tabsList: [
        {
          title: '基础信息',
          key: 1,
        },
        {
          title: '审批流程',
          key: 2,
        },
      ],
      detailInfo: {},
      detailsList: [],
      isUnfold: false,
      auditAuthority: 0,
      requestType: 0,
      resendDetail: {},
      auditAuthority: 0,
      resendAuditAuthority: 0,
    };
  },
  components: {
    ApprovalProcess: () => import('@/components/business/approvalProcess/index.vue'),
    DetailTop: () => import('@/components/business/detailTop/index.vue'),
    MyCard: () => import('@/components/basics/MyCard.vue'),
    MyCell: () => import('@/components/basics/MyCell.vue'),
    MyFilesView: () => import('@/components/basics/MyFilesView.vue'),
    DetailApprovalBtn: () => import('@/components/business/detailApprovalBtn/index.vue'),
  },
  mounted() {
    // 查看是否有内容
    this.detailId = this.$route.query.id;
    this.auditAuthority = this.$route.query.auditAuthority;
    this.resendAuditAuthority = this.$route.query.resendAuditAuthority;
    if (this.detailId) {
      this.getDetailInfo()
    } else {
      console.log("打开错误");
    }
  },
  methods: {
    getDetailInfo() {
      cashdetail({id: this.detailId}).then(res => {
        let obj = res.resultData;
        this.requestType = obj.requestType;
        this.detailInfo = obj.cashRequirementResends;
        this.resendDetail = this.detailInfo[this.detailInfo.length-1]
      })
    },
    handleUnfold(){
      this.detailsList = this.detailInfo.details;
      this.isUnfold = false;
    },
    handleCutPage(){
      this.$router.replace({
        name: 'withdrawalView',
        query: {
          id: this.detailId,
          auditAuthority: this.auditAuthority,
          resendAuditAuthority: this.resendAuditAuthority,
        }
      })
    },
    handleStatus(status){
      if(status === 2){ // 驳回
        return rejectImg;
      } else if(status === 3){ // 通过
        return passImg;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.reimburseView{
  .content-wrap{
    overflow: auto;
    height: calc(100vh - 172px);
    .basics-wrap{
      position: relative;
      .status-img{
        width: 50%;
        position: absolute;
        z-index: 99;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        opacity: 0.3;
      }
    }
    .details-list-wrap{
      .detail-item{
        background-color: #f7f8fcc9;
        border-radius: 6px;
        padding: 4px 10px;
        margin: 10px 0;
        @mixin main{
          display: flex;
          justify-content: space-between;
          margin: 10px 0;
          .detail-cell{
            flex: 1;
            display: flex;
            flex-direction: column;
            &:nth-child(1){
              text-align: left;
            }
            .title{
              font-size: 12px;
              color: $gray;
            }
            .value{
              margin: 4px 0;
              font-size: 14px;
              font-weight: 600;
              color: $font;
            }
          }
        }
        >.main-two{
          @include main;
          .detail-cell{
            &:nth-child(2){
              text-align: right;
            }
          }
        }
      }
      .unfold-wrap{
        color: $gray;
        font-size: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        .unfold{
          margin-right: 4px;
        }
      }
    }
    >.my-card{
      >.contract-list{
        margin: 10px 0;
        text-align: right;
      }
    }
  }
  >.on-approval{
    height: calc(100vh - 214px);
  }
}

</style>